import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function usePlantList() {
  // Use toast
  const toast = useToast();

  const refPlantListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "select", label: VueI18n.t("select"), sortable: false },
    { key: "pictureurl", label: VueI18n.t("plantName") },
    { key: "address", label: VueI18n.t("address"), sortable: false },

    {
      key: "responsiblemobile",
      label: VueI18n.t("resonsibleMobile"),
      sortable: false,
    },
    { key: "email", label: VueI18n.t("email"), sortable: false },
    { key: "edit", label: VueI18n.t("edit"), sortable: false },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refPlantListTable.value
      ? refPlantListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refPlantListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );
  const fetchPlants = async (ctx, callback) => {
    var plant = await store
      .dispatch("plantsModule/fetchPlants")

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("plants") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    if (searchQuery.value != "")
      plant = plant.filter((x) =>
        x.plantname
          .toLowerCase()
          .startsWith(searchQuery.value.toLocaleLowerCase())
      );
    return plant;
  };
  const deletePlant = (ctx) => {
    store
      .dispatch("plantsModule/deletePlant", ctx)
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("plant") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: VueI18n.t("notDeleted", { type: VueI18n.t("plant") }),
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchPlants,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPlantListTable,
    deletePlant,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
