<template>
  <b-sidebar
    id="edit-plant-sidebar"
    :visible="isEditPlantSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblankplant).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("updatePlant") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-media no-body class="p-2">
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="
                'https://api.optiformula.com/Plant_Pictures/' +
                blankPlantData.pictureurl
              "
              height="100"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            {{ $t("upload") }}
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->

          <!--/ reset -->
          <b-card-text></b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankplant
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- plantName -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('plantName')" label-for="plantname">
              <b-form-input
                id="plantname"
                v-model="blankPlantData.plantname"
                autofocus
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tonaj -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="tonaj"
          >
            <b-form-group :label="$t('tonaj')" label-for="tonaj">
              <b-form-input
                id="addtonaj"
                v-model="blankPlantData.tonaj"
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />
            </b-form-group>
          </validation-provider>
          <!-- responsible -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="responsible"
          >
            <b-form-group :label="$t('resonsible')" label-for="responsible">
              <b-form-input
                id="responsible"
                v-model="blankPlantData.responsible"
                autofocus
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- responsiblemobile -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="responsiblemobile"
          >
            <b-form-group
              :label="$t('resonsibleMobile')"
              label-for="responsiblemobile"
            >
              <b-form-input
                id="responsiblemobile"
                v-model="blankPlantData.responsiblemobile"
                autofocus
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- email -->
          <validation-provider
            #default="validationContext"
            rules="required|email"
            name="email"
          >
            <b-form-group :label="$t('email')" label-for="email">
              <b-form-input
                id="email"
                v-model="blankPlantData.email"
                autofocus
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- city -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="city"
          >
            <b-form-group :label="$t('city')" label-for="city">
              <b-form-input
                id="city"
                v-model="blankPlantData.city"
                autofocus
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- address -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="address"
          >
            <b-form-group :label="$t('address')" label-for="address">
              <b-form-textarea
                id="address"
                v-model="blankPlantData.address"
                autofocus
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- loclat -->

          <validation-provider
            #default="validationContext"
            rules="required"
            name="loclat"
          >
            <b-form-group :label="$t('locLat')" label-for="loclat">
              <b-form-input
                id="loclat"
                v-model="blankPlantData.loclat"
                autofocus
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- loclong -->

          <validation-provider
            #default="validationContext"
            rules="required"
            name="loclong"
          >
            <b-form-group :label="$t('locLong')" label-for="loclong">
              <b-form-input
                id="loclong"
                v-model="blankPlantData.loclong"
                autofocus
                :state="
                  formValidation(resetblankplant).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCardText,
  BFormFile,
  BMedia,
  BMediaBody,
  BMediaAside,
  BImg,
  BLink,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCardText,
    BFormFile,
    BMedia,
    BMediaBody,
    BImg,
    BMediaAside,
    BLink,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditPlantSidebarActive",
    event: "update:is-edit-plant-sidebar-active",
  },
  props: {
    isEditPlantSidebarActive: {
      type: Boolean,
      required: true,
    },
    plant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankPlantData: {},
      imgStr: "",
      cins: [
        {
          label: "Yoğun Yem",
          value: 2,
        },
      ],
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async changed(val) {
      this.imgStr = "";
      this.blankPlantData = this.plant.item;

      this.$emit("update:is-edit-plant-sidebar-active", val);
    },
    onSubmit() {
      this.blankPlantData.imgstr = this.imgStr;
      store
        .dispatch("plantsModule/updatePlant", this.blankPlantData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-plant-sidebar-active", false);
        });
    },
    async resetblankplant() {
      blankPlantData = await this.fetchNewIngredient;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-plant-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
